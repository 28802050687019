import { AxiosPromise } from 'axios';
import { formatISO } from 'date-fns';
import { normalizeUri, requestApi } from 'services/axios';
import {
    CollectionJsonLD,
    Item,
    MinMaxRange,
    PriceRange,
    Rental,
    RentalItemPriceRange,
    RentalPicture,
    Unavailability,
} from 'types/api';

export const bedroomRange = (): AxiosPromise<MinMaxRange> =>
    requestApi<MinMaxRange>({
        url: '/rentals/aggregate/bedroom_range',
    });

export const priceRange = (): AxiosPromise<PriceRange> =>
    requestApi<PriceRange>({
        url: '/rentals/aggregate/price_range',
    });

export const randomRentals = (): AxiosPromise<CollectionJsonLD<Rental>> =>
    requestApi<CollectionJsonLD<Rental>>({
        url: '/rentals/random',
        params: {
            published: 1,
            perPage: 4,
        },
    });

export const firstPicture = (id: string): AxiosPromise<CollectionJsonLD<RentalPicture>> =>
    requestApi<CollectionJsonLD<RentalPicture>>({
        url: `${normalizeUri('rentals', id)}/pictures`,
        params: {
            perPage: 1,
            order: {
                position: 'ASC',
            },
            groups: ['website:picture:output', 'website:mediaObject:output'],
        },
    });

export const unavailabilities = (id: string, shared = false): AxiosPromise<CollectionJsonLD<Unavailability>> =>
    requestApi({
        url: `${normalizeUri('rentals', id)}/unavailabilities`,
        params: {
            perPage: 100,
            periodEnd: {
                after: formatISO(new Date(), { representation: 'date' }),
            },
        },
        headers: {
            'WITH-SHARED': shared ? '1' : '0',
        },
    });

export const similars = (
    id: string,
    periodStart: string,
    periodEnd: string,
    bedrooms: number,
    highlights: Array<string>,
): AxiosPromise<CollectionJsonLD<Rental>> => {
    const params: Record<string, any> = {
        periodStart,
        periodEnd,
        bedrooms,
        highlights,
        groups: ['website:rental:output', 'website:specialOffer:output', 'website:price:output', 'website:location:output'],
    };

    return requestApi({
        url: `${normalizeUri('rentals', id)}/similars`,
        params,
    });
};

export const priceRangeItem = (
    id: string,
    periodStart?: string,
    periodEnd?: string,
    bedrooms?: number,
): AxiosPromise<RentalItemPriceRange> =>
    requestApi<MinMaxRange>({
        url: `${normalizeUri('rentals', id)}/price_range`,
        params: {
            periodStart,
            periodEnd,
            bedrooms,
        },
    });

export const searchRentals = (
    page: number,
    periodStart: string,
    periodEnd: string,
    bedrooms: number,
    rentals: Array<Item | string>,
    locations: Array<Item | string>,
    highlights: Array<Item | string>,
    prices: MinMaxRange,
    sort: string,
    specialOffer: boolean,
): AxiosPromise<CollectionJsonLD<Rental>> => {
    const params: Record<string, any> = {
        page,
        published: 1,
        bedroomCount: {
            gte: bedrooms,
        },
        id: rentals.filter((item) => 'string' === typeof item),
        location: locations.filter((item) => 'string' === typeof item),
        highlights: highlights.filter((item) => 'string' === typeof item),
        specialOffer,
        price: {
            min: prices.min,
            max: prices.max,
        },
        groups: ['website:rental:output', 'website:specialOffer:output'],
    };

    if (periodStart.length > 0 && periodEnd.length > 0) {
        params.availability = `${periodStart}..${periodEnd}`;
    }

    return requestApi<CollectionJsonLD<Rental>>({
        url: `/rentals?${sort}`,
        params,
    });
};
