import EventEmitter from 'events';
import React from 'react';

const Bus = new EventEmitter();

type StorageEvent<T> = {
    key: string;
    value: T;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useStickyState = <T>(
    key: string,
    defaultValue: T,
    watch = false,
    format?: (_value: any) => T,
): [T, (_value: T) => void] => {
    Bus.setMaxListeners(20);
    const setStorageValue = (newValue: T): void => {
        Bus.emit('setStickyValue', { key, value: newValue } as StorageEvent<T>);
        setValue(newValue);
    };
    const [value, setValue] = React.useState((): any => {
        if (typeof window === 'undefined') {
            return defaultValue;
        }

        const stickyValue = window.localStorage.getItem(key);

        return stickyValue !== null
            ? format
                ? format(JSON.parse(stickyValue))
                : JSON.parse(stickyValue)
            : defaultValue;
    });

    React.useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    React.useEffect(() => {
        let unmounted = false;
        if (watch) {
            Bus.addListener('setStickyValue', ({ key: eventKey, value: newValue }: StorageEvent<T>): void => {
                if (!unmounted && key === eventKey) {
                    setValue(newValue);
                }
            });
        }

        return (): any => (unmounted = true);
    }, [watch]);

    return [value, setStorageValue];
};

export default useStickyState;
