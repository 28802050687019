import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDiv = styled.div`
    background-color: ${(props): string => props.theme.ivr.colors.deepBlue['100']};
    bottom: 0;
    color: ${(props): string => props.theme.ivr.colors.white['100']};
    left: 0;
    padding: 0.2rem 1.2rem;
    position: absolute;
    text-transform: uppercase;
    & > span {
        color: ${(props): string => props.theme.ivr.colors.golden['100']};
        font-weight: 700;
    }
`;

const SpecialOffer = (): ReactElement => {
    const { t } = useTranslation(['rental']);

    return (
        <StyledDiv>
            <Trans t={t}>card.special_offer</Trans>
        </StyledDiv>
    );
};

export default SpecialOffer;
