import React, { memo, useState, useEffect } from 'react';

// Assurez-vous que le chemin d'accès à votre placeholder est correct
import placeholderPicture from 'images/rental-placeholder.svg';

// Ce type doit représenter la structure de votre objet 'picture'.
type PictureType = {
    contentUrl?: string;
};

type Props = {
    picture: PictureType | undefined;
    title: string;
};

const Picture = memo(({ picture, title }: Props) => {
    const [pictureUrl, setPictureUrl] = useState('');

    useEffect(() => {
        if (picture && picture.contentUrl) {
            setPictureUrl(picture.contentUrl);
        } else {
            setPictureUrl(placeholderPicture);
        }
    }, [picture]);

    const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = placeholderPicture;
    };

    return (
        <div className="h-100 w-100">
            <img
                src={pictureUrl}
                alt={title}
                className="img-fluid"
                onError={handleError}
            />
        </div>
    );
});

export default Picture;
