import { OptionTypeBase, Styles } from 'react-select';
import { CSSObject } from 'styled-components';

const golden = '#bb9981';

const styles: Styles<OptionTypeBase, false> = {
    clearIndicator: (base: CSSObject): CSSObject => ({
        ...base,
        color: golden,
        cursor: 'pointer',
        ':hover': {
            ...base[':hover'],
            color: golden,
        },
    }),
    container: (base: CSSObject): CSSObject => ({
        ...base,
        margin: '10px 0',
        padding: '0',
        width: '100%',
    }),
    indicatorSeparator: (base: CSSObject): CSSObject => ({
        ...base,
        backgroundColor: 'transparent',
    }),
    dropdownIndicator: (base: CSSObject, state): CSSObject => ({
        ...base,
        color: golden,
        transform: `rotate(${state.isFocused ? 180 : 0}deg)`,
    }),
    control: (base: CSSObject): CSSObject => ({
        ...base,
        borderColor: golden,
        borderRadius: '3px',
        boxShadow: 'none',
        padding: '6px 0 6px 8px',
        ':hover': {
            ...base[':hover'],
            borderColor: golden,
        },
    }),
    menu: (base: CSSObject): CSSObject => ({
        ...base,
        margin: '0',
        zIndex: 99,
    }),
    option: (base: CSSObject, props): CSSObject => ({
        ...base,
        backgroundColor: props.isFocused || props.isSelected ? golden : base.backgroundColor,
        color: props.isFocused || props.isSelected ? '#fff' : base.color,
        textTransform: 'uppercase',
    }),
    multiValue: (base: CSSObject): CSSObject => ({
        ...base,
        backgroundColor: golden,
        borderRadius: '10px',
        color: '#fff',
        textTransform: 'uppercase',
    }),
    multiValueLabel: (base: CSSObject): CSSObject => ({
        ...base,
        color: '#fff',
    }),
    multiValueRemove: (base: CSSObject): CSSObject => ({
        ...base,
        cursor: 'pointer',
        ':hover': {
            ...base[':hover'],
            backgroundColor: golden,
            borderRadius: '10px',
            color: '#fff',
        },
    }),
};

export default styles;
