import { useRentalPriceRange } from 'hooks/usePrices';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Rental } from 'types/api';

type Props = {
    rental: Pick<Rental, 'prices'>;
};

const StyledDiv = styled.div.attrs(() => ({
    className: 'col d-flex justify-content-end',
}))`
    font-size: 0.9rem;
    line-height: 2rem;
    text-transform: uppercase;
    white-space: nowrap;
    & > span {
        color: ${(props): string => props.theme.ivr.colors.golden['100']};
        font-size: 1.2rem;
        font-weight: 700;
    }
`;

const Price = ({ rental }: Props): ReactElement => {
    const { t } = useTranslation('rental');
    const data = useRentalPriceRange(rental);

    return (
        <StyledDiv>
            <Trans t={t} values={{ value: data.min }}>
                rental:card.price
            </Trans>
        </StyledDiv>
    );
};

export default Price;
