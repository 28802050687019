import useStickyState from 'hooks/useStickyState';
import { Rental, RentalItemPriceRange } from 'types/api';

const useRentalPriceRange = (rental: Pick<Rental, 'prices'>): RentalItemPriceRange => {
    const [periodStart] = useStickyState<string>('periodStart', '');
    const [periodEnd] = useStickyState<string>('periodEnd', '');
    const [bedrooms] = useStickyState<number>('bedrooms', 1, false, (value) => parseInt(value, 10));

    let result = (rental.prices || []).reduce(
        (reduced, price) => {
            if (null !== price.season) {
                if (
                    price.bedroomCount <= bedrooms &&
                    (('' === periodStart && '' === periodEnd) ||
                        (price.season.periodStart <= periodStart && price.season.periodEnd >= periodStart) ||
                        (price.season.periodStart <= periodEnd && price.season.periodEnd >= periodEnd))
                ) {
                    if (null === reduced.min || price.price < reduced.min) {
                        reduced.min = price.price;
                    }
                    if (null === reduced.max || price.price > reduced.max) {
                        reduced.max = price.price;
                    }
                }
            }

            return reduced;
        },
        {
            min: null,
            max: null,
        } as { min: number | null; max: number | null },
    );

    if (null === result.min) {
        result = (rental.prices || []).reduce(
            (reduced, price) => {
                if (null === reduced.min || price.price < reduced.min) {
                    reduced.min = price.price;
                }
                if (null === reduced.max || price.price > reduced.max) {
                    reduced.max = price.price;
                }
                return reduced;
            },
            {
                min: null,
                max: null,
            } as { min: number | null; max: number | null },
        );
    }

    return {
        min: result.min ?? 0,
        max: result.max ?? 0,
    };
};

export { useRentalPriceRange };
