import { Card as BsCard, Col } from 'react-bootstrap';
import styled from 'styled-components';
import placeholderPicture from 'images/rental-placeholder.svg';

const Card = styled(Col)`
    cursor: pointer;
`;

const InnerCard = styled(BsCard).attrs(() => ({
    className: 'h-100',
}))`
    color: var(--bs-body-color);
    text-decoration: none;
    &:hover {
        box-shadow: 0 0 16px rgb(33 33 33 / 60%);
    }
`;

const Picture = styled.div.attrs(() => ({
    className: 'flex-grow-1 h-100 w-100',
}))`
    img {
        display: block;
        height: 100%;
        object-fit: cover;
        width: 100%;
        &:after {
            background: url('${placeholderPicture}') no-repeat scroll 0 0;
            content: '';
            display: block;
            font-weight: 300;
            height: 100%;
            left: 0;
            line-height: 2;
            position: absolute;
            text-align: center;
            top: 0;
            width: 100%;
        }
    }
`;

const Title = styled.div.attrs(() => ({
    className: 'col-7 d-flex',
}))`
    color: ${(props): string => props.theme.ivr.colors.black['100']};
    font-family: ${(props): string => props.theme.ivr.fonts.playfair};
    font-size: 1.1rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.3rem;
    text-transform: uppercase;
`;

const Location = styled.div.attrs(() => ({
    className: 'col-5 d-flex justify-content-end align-items-center',
}))`
    color: ${(props): string => props.theme.ivr.colors.grey.d50};
    font-size: 0.8rem;
    line-height: 1.3rem;
    text-transform: uppercase;
`;

const Spacer = styled.div.attrs(() => ({
    className: 'col-12 my-2',
}))`
    border-bottom: 1px dashed ${(props): string => props.theme.ivr.colors.golden['100']};
`;

const Bedrooms = styled.div.attrs(() => ({
    className: 'col-auto d-flex',
}))`
    line-height: 2rem;
`;

export { Bedrooms, Card, Location, Picture, InnerCard, Spacer, Title };
