import { cssBreakpoint } from 'device';
import { IoIosCalendar } from 'react-icons/io';
import { IoChevronForward, IoClose } from 'react-icons/io5';
import styled, { css } from 'styled-components';

const DatePickerContainer = styled.div<{ $readonly: boolean }>`
    background-color: ${(props): string =>
        props.$readonly ? 'rgba(200, 200, 200, 0.3)' : props.theme.ivr.colors.white[100]};
    border: 1px solid ${(props): string => props.theme.ivr.colors.golden[100]};
    border-radius: 3px;
    cursor: ${(props): string => (props.$readonly ? 'default' : 'pointer')};
    display: inline-block;
    height: 50px;
    line-height: 30px;
    margin: 10px 0;
    padding: 10px 30px;
    position: relative;
    text-align: center;
    width: 100%;
`;

const icon = css`
    color: ${(props): string => props.theme.ivr.colors.golden[100]};
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
`;

const CalendarIcon = styled(IoIosCalendar).attrs(() => ({
    size: 30,
}))`
    ${icon};
    left: 0;
    transform: translateY(-50%);
`;

const ArrowIcon = styled(IoChevronForward).attrs(() => ({
    size: 20,
}))`
    ${icon};
    left: 50%;
    @media screen and ${cssBreakpoint('md')} {
        height: 32px;
        width: 32px;
    }
`;

const CloseIcon = styled(IoClose).attrs(() => ({
    size: 16,
}))`
    ${icon};
    right: 0;
`;

const DatePickerValue = styled.div`
    color: #35343d;
    display: inline-block;
    font-size: 1rem;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    width: calc(50%);
`;

const Start = styled(DatePickerValue)``;

const End = styled(DatePickerValue)``;

export { ArrowIcon, CalendarIcon, CloseIcon, DatePickerContainer, End, Start };
