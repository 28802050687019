import Picture from 'components/Rental/Show/Picture';
import React, { ReactElement } from 'react';
import { Picture as PictureType } from 'types/api';
import { Picture as PictureContainer } from './styled';

type Props = {
    picture: PictureType | undefined;
    title: string;
};
const MainPicture = ({ picture, title }: Props): ReactElement => (
    <PictureContainer>
        <Picture picture={picture} title={title} />
    </PictureContainer>
);

export default MainPicture;
