import React, { ForwardedRef, forwardRef, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import TopLabel from 'components/Form/TopLabel';
import { ArrowIcon, CalendarIcon, CloseIcon, DatePickerContainer, End, Start } from './styled';

type Props = {
    label?: string | ReactElement;
    value?: string;
    onClick?: () => any;
    handleClear?: () => any;
    readonly?: boolean;
};
const DatePickerInput = forwardRef(
    ({ label, value, onClick, handleClear, readonly }: Props, ref: ForwardedRef<HTMLDivElement>) => {
        const { t } = useTranslation('form');
        const [start, end] = (value || '')?.split(/ - /) as [string | undefined, string | undefined];
        return (
            <DatePickerContainer ref={ref} $readonly={!!readonly}>
                {label && <TopLabel>{label}</TopLabel>}
                <CalendarIcon />
                <Start onClick={onClick}>{start ? start : t('form:search.start')}</Start>
                <ArrowIcon />
                <End onClick={onClick}>{end ?? t('form:search.end')}</End>
                {start && end && handleClear && <CloseIcon onClick={handleClear} />}
            </DatePickerContainer>
        );
    },
);

export default DatePickerInput;
