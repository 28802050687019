import { useTranslation } from 'react-i18next';

type Locale = {
    base: string;
    f3166: string;
    f639: string;
    ietf: string;
    ietfu: string;
};

const useLanguage = (): Locale => {
    const { i18n } = useTranslation();

    const locale = i18n.language;
    const country = locale.startsWith('fr') ? 'FR' : 'US';

    return {
        base: locale,
        f3166: country,
        f639: locale.substr(0, 2),
        ietf: `${locale.substr(0, 2)}-${country}`,
        ietfu: `${locale.substr(0, 2)}_${country}`,
    };
};

export default useLanguage;
