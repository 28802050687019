import styled from 'styled-components';

const TopLabel = styled.label`
    background-color: ${(props): string => props.theme.ivr.colors.white[100]};
    color: ${(props): string => props.theme.ivr.colors.grey.d50};
    font-size: 0.9rem;
    left: 20px;
    padding: 0 0.2rem;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    white-space: nowrap;
    width: auto;
    z-index: 1;

    span {
        color: ${(props): string => props.theme.ivr.colors.black['100']};
        font-weight: bold;
    }
`;

export default TopLabel;
