import MainPicture from 'components/Rental/Card/MainPicture';
import Price from 'components/Rental/Card/Price';
import SpecialOffer from 'components/Rental/Card/SpecialOffer';
import {Link} from 'gatsby';
import useRentalSeo from 'hooks/useRentalSeo';
import React, {ReactElement} from 'react';
import {ColProps, Row} from 'react-bootstrap';
import {Trans, useTranslation} from 'react-i18next';
import {IoMdPin} from 'react-icons/io';
import {useQuery} from 'react-query';
import {firstPicture} from 'services/rental';
import { CollectionJsonLD, Picture, Rental, RentalPicture } from 'types/api';
import {Bedrooms, Card, InnerCard, Location, Spacer, Title} from './styled';

type Props = ColProps & {
    rental: Pick<Rental,
        | 'id'
        | 'title'
        | 'hasSpecialOffers'
        | 'specialOffers'
        | 'mainPicture'
        | 'pictures'
        | 'slug'
        | 'city'
        | 'location'
        | 'published'
        | 'bedroomCount'
        | 'prices'>;
};

const RentalCard = ({rental, ...colProps}: Props): ReactElement => {
    const {t} = useTranslation('rental');

    useQuery(['rental_main_picture', rental.id], () => firstPicture(rental.id), {
        enabled: rental.mainPicture === undefined,
        onSuccess: (pictures: CollectionJsonLD<Picture>): void => {
            rental.mainPicture = pictures['hydra:member'][0];
        },
        select: (response): CollectionJsonLD<Picture> => ({
            ...response.data,
            'hydra:member': response.data['hydra:member'].map((rentalPicture: RentalPicture) => ({
                position: rentalPicture.position,
                ...rentalPicture.picture,
            })),
        }),
    });

    const url = useRentalSeo(rental);

    return (
        <Card {...colProps} className="mb-4 align-items-center">
            <Link to={url} className="text-decoration-none">
                <InnerCard>
                    <div className="text-center flex-grow-1 d-flex">
                        <div className="flex-grow-1 d-flex position-relative">
                            <MainPicture picture={rental.mainPicture} title={rental.title}/>
                            {(rental.hasSpecialOffers || (rental.specialOffers?.length || 0) > 0) && <SpecialOffer/>}
                        </div>
                    </div>
                    <div className="p-2">
                        <Row className="g-0">
                            <Title>{rental.title}</Title>
                            <Location>
                                <IoMdPin className="me-2"/>
                                {rental.location.title}
                            </Location>
                            <Spacer/>
                            <Bedrooms>
                                <Trans t={t} count={parseInt(rental.bedroomCount.toString(), 10)}>
                                    card.bedroom
                                </Trans>
                            </Bedrooms>
                            <Price rental={rental}/>
                        </Row>
                    </div>
                </InnerCard>
            </Link>
        </Card>
    );
};

export default RentalCard;
